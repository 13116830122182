<template>
    <section>
        <tabs :tabs="tabs" class="border-bottom mb-3" />
        <router-view />
    </section>
</template>

<script>
export default {
    data(){
        return {
            tabs: [
                { titulo: 'Pedidos Activos', ruta: 'tendero.pedidos.activos' },
                { titulo: 'Historial de pedidos', ruta: 'tendero.historial-pedidos' }
            ]
        }
    }
}
</script>